import React, { useEffect, useImperativeHandle, useRef } from "react";
import { ZoomPanPinch } from "../../core/instance.core";
import { getControls } from "../../utils";
export var Context = React.createContext(null);
var getContent = function (children, ctx) {
    if (typeof children === "function") {
        return children(ctx);
    }
    return children;
};
export var TransformWrapper = React.forwardRef(function (props, ref) {
    var instance = useRef(new ZoomPanPinch(props)).current;
    var content = getContent(props.children, getControls(instance));
    useImperativeHandle(ref, function () { return getControls(instance); }, [instance]);
    useEffect(function () {
        instance.update(props);
        return function () {
            instance.cleanupWindowEvents();
        };
    }, [instance, props]);
    return React.createElement(Context.Provider, { value: instance }, content);
});
